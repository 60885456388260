<template>
    <div class="wrap about">
        <common-header></common-header>
        <div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
            <div class="main">
                <div class="left-menu">
                    <ul>
                        <li v-for="(item, i) in navList">
                            <a :class="{active: idx == i}" href="javascript:;" @click="chooseMenu(i)">{{item.title}}</a>
                        </li>
                    </ul>
                </div>
                <div class="right-main">
                    <div id="cate0">
                        <h3 class="part-title">公司简介</h3>
                        <h4 class="small-title">COMPANY INTRODUCTION</h4>
                        <div class="content" v-html="company.content"></div>
                    </div>
                    <div id="cate1">
                        <h3 class="part-title">全国基地</h3>
                        <h4 class="small-title">NATIONAL BASE</h4>
                        <div class="leader-img" v-if="leader.length">
                            <swiper ref="mySwiper" :options="swiperOptions">
                                <swiper-slide v-for="item in leader">
                                    <img :src="item.img" alt="">
                                    <el-image :src="item.img" :preview-src-list="leaderImgs">
                                    </el-image>
                                    <div class="desc">{{item.remark}}</div>
                                </swiper-slide>
                            </swiper>
                        </div>
                        <a class="view-more" href="javascript:;" @click="viewMore">查看更多</a>
                    </div>
                    <div id="cate2">
                        <h3 class="part-title">品牌荣誉</h3>
                        <h4 class="small-title">BRAND HONORS</h4>
                        <div class="honor">
                            <div class="honor-nav">
                                <swiper v-if="honor.length" ref="mySwiper" :options="honorOptions">
                                    <swiper-slide v-for="(item, idx) in honor">
                                        <a href="javascript:;" :class="{active: idx == current}"
                                            @click="chooseCate(idx)">{{item.title}}</a>
                                    </swiper-slide>
                                </swiper>
                            </div>
                            <transition :name="isLeft ? 'slide-l' : 'slide-r'" appear>
                                <div class="honor-img" v-if="honor[current] && honor[current].imgList">
                                    <el-image v-for="img in honor[current].imgList" :src="img"
                                        :preview-src-list="honor[current].imgList">
                                    </el-image>
                                </div>
                            </transition>
                        </div>
                    </div>
                    <div id="cate3">
                        <h3 class="part-title">售后服务</h3>
                        <h4 class="small-title">AFTER-SALE SERVICE</h4>
                        <div class="content" v-html="service.content"></div>
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'

    export default {
        name: 'About',
        components: {
            CommonHeader,
            BlackFooter,
            swiper, swiperSlide
        },
        data() {
            return {
                idx: 0,
                banner: '',
                navList: [
                    { title: '公司简介' },
                    { title: '全国基地' },
                    { title: '品牌荣誉' },
                    { title: '售后服务' },
                ],
                company: {},
                service: {},
                leader: [],
                leaderImgs: [],
                swiperOptions: {
                    slidesPerView: 2,
                    spaceBetween: 72,
                    freeMode: true,
                    loop: true,
                    autoplay: {
                        delay: 1000,
                        disableOnInteraction: false,
                    },
                    speed: 800
                },
                honor: [],
                current: 0,
                honorOptions: {
                    slidesPerView: 'auto',
                    spaceBetween: 72,
                    freeMode: true,
                    mousewheel: true
                },
                myTimer: -1,

                isLeft: false
            }
        },
        mounted() {
            this.pageInit();
            window.addEventListener("scroll", this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        methods: {
            handleScroll() {
                let scrollPosition =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop ||
                    0;
                for (let i = 0; i < 4; i++) {
                    let sectionTop = document.getElementById('cate' + i).offsetTop;
                    let sectionHeight = document.getElementById(
                        'cate' + i
                    ).offsetHeight;
                    if (
                        scrollPosition >= sectionTop - sectionHeight / 3 &&
                        scrollPosition < sectionTop + sectionHeight - sectionHeight / 3
                    ) {
                        this.idx = i;
                    }
                }
            },
            pageInit() {
                this.$api.getActivityData('PCBANNER').then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.banner = data.filter(item => item.model == 'BANNER_6')[0].img;
                    }
                });

                this.$api.getArticleList({ type: 2 }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        let row = data.rows[0];
                        this.$api.getArticleDetail(row.articleId).then(result => {
                            this.company = result.data.data;
                        });
                    }
                });

                this.$api.getArticleList({ type: 3 }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        let row = data.rows[0];
                        this.$api.getArticleDetail(row.articleId).then(result => {
                            this.service = result.data.data;
                        });
                    }
                });

                this.$api.getCaring({ size: 10 }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.leader = data.records;
                        this.leaderImgs = data.records.map(item => item.img);
                    }
                });

                this.$api.getHonor({ size: 999 }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.honor = data.rows;
                    }
                });

            },
            viewMore() {
                this.$router.push({
                    path: '/aboutleader'
                })
            },
            chooseMenu(index) {
                this.idx = index;
                let jumpHeight = (document.getElementById('cate' + index)).offsetTop + 80;
                let top = document.documentElement.scrollTop;
                if (jumpHeight > top) {
                    let heigth = jumpHeight - top;
                    if (this.myTimer == -1) {
                        if (heigth > 10000) {
                            let num = Number((heigth / 80).toFixed(0));
                            this.myTimer = setInterval(() => {
                                top += num;
                                if (top >= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        } else {
                            this.myTimer = setInterval(() => {
                                top += 80;
                                if (top >= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        }
                    }
                } else {
                    let heigth = top - jumpHeight;
                    if (this.myTimer == -1) {
                        if (heigth > 10000) {
                            let num = Number((heigth / 80).toFixed(0));
                            this.myTimer = setInterval(() => {
                                top -= num;
                                if (top <= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        } else {
                            this.myTimer = setInterval(() => {
                                top -= 80;
                                if (top <= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        }
                    }
                }
            },
            chooseCate(idx) {
                if (idx > this.current) {
                    this.isLeft = false;
                }
                else {
                    this.isLeft = true;
                }
                this.current = -1;
                setTimeout(() => {
                    this.current = idx;
                }, 100);
            }
        }
    }
</script>
<style scoped>
    .right-main {
        margin-left: 160px;
        padding-top: 400px;
        min-height: calc(100vh - 100px);
        padding-bottom: 80px;
        overflow: hidden;
    }

    .part-title {
        line-height: 30px;
        font-size: 24px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        text-align: center;
        color: #444;
        margin: 80px 0 16px;
    }

    .small-title {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        text-align: center;
        color: #444;
        margin-bottom: 24px;
    }

    .content {
        padding-bottom: 8px;
    }

    .leader-img {
        width: 1244px;
        overflow: hidden;
        height: 400px;
        background: url(~@/assets/icon_about_bg.png) repeat-x center;
        background-size: auto 228px;
    }

    .desc {
        position: absolute;
        left: 0;
        bottom: 22px;
        max-width: 218px;
        max-height: 118px;
        overflow: hidden;
        background: #f2f2f2;
        border-radius: 8px;
        padding: 20px 11px;
        line-height: 21px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        color: #444;
    }

    .view-more {
        width: 120px;
        background: #ffffff;
        border: 1px solid #ccc;
        font-size: 14px;
        text-align: center;
        color: #ccc;
        line-height: 26px;
        display: block;
        margin: 46px auto 0;
    }

    .honor-nav {
        line-height: 36px;
        font-size: 20px;
        color: #444444;
        margin-bottom: 16px;
        white-space: nowrap;
        overflow: hidden;
    }

    .honor-nav /deep/ .swiper-slide {
        width: auto;
    }

    .honor-nav a {
        margin: 0 25px;
    }

    .honor-nav a.active {
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #34b8ff;
    }

    .honor-img {
        overflow: hidden;
    }

    .left-menu {
        width: 150px;
        position: fixed;
        top: 194px;
        z-index: 999;
        left: 50%;
        margin-left: -695px;
    }

    .left-menu a {
        width: 150px;
        height: 43px;
        display: block;
        opacity: 0.82;
        background: rgba(42, 180, 255, 0.82);
        border-radius: 24px 0px 24px 0px;
        text-align: center;
        line-height: 43px;
        margin-bottom: 14px;
        color: #fff;
        font-size: 16px;
        overflow: hidden;
    }

    .left-menu a.active,
    .left-menu a:hover {
        background: #FFDE23;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        letter-spacing: 2px;
        opacity: 1;
    }
</style>
<style>
    .about .leader-img .el-image {
        width: 586px;
        height: 400px;
        display: block;
        opacity: 0;
        position: absolute;
        left: 22px;
        top: 0;
    }

    .about .leader-img img {
        width: 586px;
        height: 400px;
        border-radius: 10px;
        margin-left: 22px;
        margin-right: 72px;
    }

    .about .honor-img img {
        width: 303px;
        height: 408px;
    }

    .about .honor-img .el-image {
        display: block;
        margin-right: 6px;
        margin-bottom: 6px;
        float: left;
        width: 303px;
        height: 408px;
    }

    .about .honor-img .el-image:nth-child(4n) {
        margin-right: 0;
    }

    .slide-l-enter-active {
        animation: moveL .5s;
    }

    .slide-l-leave-active {
        animation: moveL .5s reverse;
    }

    .slide-r-enter-active {
        animation: move .5s;
    }

    .slide-r-leave-active {
        animation: move .5s reverse;
    }

    @keyframes moveL {
        from {
            transform: translateX(-100%);
        }

        to {
            transform: translate(0);
        }
    }

    @keyframes move {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translate(0);
        }
    }
</style>