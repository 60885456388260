<template>
    <div class="footer">
        <div class="footer-main">
            <div class="footer-img">
                <img src="@/assets/icon_slogan.jpg" alt="">
                <img src="@/assets/icon_phone.jpg" alt="">
            </div>
            <p>浙江星星冷链集成股份有限公司 版权所有</p>
            <p>地址：浙江省台州市椒江区洪家街道白云山南路1688号 </p>
            <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备16039478号-7</a></p>
        </div>
		<el-backtop class="go-top" :bottom="100" :visibility-height="50"><div></div></el-backtop>
    </div>
  </template>
  
  <script>
  export default {
    props: {
    },
    data(){
      return {
      }
    },
    mounted () {
    },
    methods: {
    }
  }
  </script>
  <style scoped>
      .footer{
          background: #282828;
      }
      .footer-main{
          text-align: center;
          padding: 45px 0 40px;
          font-size: 12px;
          line-height: 1;
          width: 1390px;
          margin: 0 auto;
      }
      .footer-main p{
        line-height: 16px;
        font-size: 16px;
        color: #fff;
        margin-top: 10px;
      }
      .footer-main p a{
          color: #fff;
      }
      .footer-img{
          overflow: hidden;
          margin-bottom: 210px;
      }
      .footer-img img{
          float: left;
      }
      .footer-img img:last-child{
          float: right;
      }
      .go-top{
          position: fixed;
          right: 16px;
          bottom: 32px;
          width: 65px;
          height: 65px;
          background: url(~@/assets/icon_top.png);
          border-radius: 0;
      }
      .go-top:hover{
          background-color: transparent;
      }
  </style>
  