<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
            <div class="main">
                <div class="right-main">
                    <div class="breadcrumb">
                        <a href="javascript:;" @click="$router.back()">{{type == 1 ? '新品发布' : '精品鉴赏'}}</a> > {{title}}
                    </div>
                    <div class="sort">
                        <span>排序方式</span>
                        <a :class="setClass('goodsPriceOrderBy')" href="javascript:;" @click="setSort(1)">价格</a>
                        <a :class="setClass('literOrderBy')" href="javascript:;" @click="setSort(2)">容量</a>
                    </div>
                    <div v-if="goodsList.length" class="product-list">
                        <div class="list-box">
                            <router-link v-for="item in goodsList" class="product-item"
                                :to="(type == 1 ? '/newrecommenddetail' : '/boutiquedetail') + '?id=' + item.goodsId">
                                <img :src="item.goodsImg" alt="">
                                <div class="goods-info">
                                    <h4>{{item.goodsType}}</h4>
                                    <p class="txt">{{item.goodsTitle}}</p>
                                    <div class="goods-tags">
                                        <span v-if="item.brand"
                                            :style="{color: item.fontColor, background: item.backgroundColor, borderColor: item.borderColor }">{{item.brand}}</span>
                                        <span v-if="item.recommendNew == 1" class="new">新品</span>
                                    </div>
                                    <p class="price">¥{{item.goodsPrice.toFixed(2)}}</p>
                                </div>
                            </router-link>
                        </div>
                        <div class="page-wrap">
                            <pagination v-show="total>0" :total="total" background
                                layout="prev, pager, next, total, jumper" :page.sync="listQuery.current"
                                :limit.sync="listQuery.size" @pagination="getList" />
                        </div>
                    </div>
                    <div v-else class="no-goods">
                        暂无商品
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    import Pagination from '@/components/Pagination'
    export default {
        name: 'NewList',
        components: {
            CommonHeader,
            BlackFooter,
            Pagination
        },
        data() {
            return {
                type: this.$route.query.type,
                id: this.$route.query.id,
                title: this.$route.query.title,
                banner: '',
                goodsList: [],
                listQuery: {
                    current: 1,
                    size: 20,
                },

                goodsPriceOrderBy: '',
                literOrderBy: '',
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.getList();
                this.$api.getActivityData('PCBANNER').then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.banner = data.filter(item => {
                            return this.type == 1 ? item.model == 'BANNER_3' : item.model == 'BANNER_5';
                        })[0].img;
                    }
                });
            },
            getList() {
                let opt = { ...this.listQuery, goodsFlagId: this.id, goodsPriceOrderBy: this.goodsPriceOrderBy, literOrderBy: this.literOrderBy };
                this.$api.getNewGoodsList(opt).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.goodsList = data.rows;
                        this.total = data.total;
                    }
                });
            },
            setSort(type) {
                let result = '';
                if (type == 1) {
                    this.literOrderBy = '';
                    if (this.goodsPriceOrderBy === '') {
                        result = 0;
                    }
                    if (this.goodsPriceOrderBy === 0) {
                        result = 1;
                    }
                    if (this.goodsPriceOrderBy === 1) {
                        result = 0;
                    }
                    this.goodsPriceOrderBy = result;
                }
                if (type == 2) {
                    this.goodsPriceOrderBy = '';
                    if (this.literOrderBy === '') {
                        result = 0;
                    }
                    if (this.literOrderBy === 0) {
                        result = 1;
                    }
                    if (this.literOrderBy === 1) {
                        result = 0;
                    }
                    this.literOrderBy = result;
                }
                this.getList();
            },
            setClass(key) {
                return {
                    on: this[key] !== '',
                    up: this[key] === 1
                }
            }
        }
    }
</script>
<style scoped>
    .right-main {
        padding-top: 432px;
        min-height: calc(100vh - 100px);
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        padding-bottom: 30px;
    }

    .sort {
        margin-bottom: 20px;
        text-align: right;
        line-height: 30px;
        font-size: 16px;
    }

    .sort span {
        margin-right: 18px;
    }

    .sort a {
        width: 102px;
        height: 32px;
        background: #fff url(~@/assets/icon_sort_down.png) no-repeat 66px center;
        border: 1px solid #c7c7c7;
        display: inline-block;
        text-align: center;
        padding-right: 16px;
    }

    .sort a.on {
        color: #50c1ff;
        border: 1px solid #50c1ff;
        background: #fff url(~@/assets/icon_sort_up_b.png) no-repeat 66px center;
    }

    .sort a.up {
        background: #fff url(~@/assets/icon_sort_down_b.png) no-repeat 66px center;
    }

    .product-list {
        padding: 0 0 32px;
    }

    .list-box {
        margin-bottom: 22px;
    }

    .list-box::after {
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .product-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 335px;
        float: left;
        margin-right: 16px;
        border: 1px solid #f6f6f6;
        margin-bottom: 16px;
        overflow: hidden;
    }

    .product-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .product-item:hover img {
        transform: scale(1.1);
    }

    .product-item:hover .txt {
        color: #285CD6;
    }

    .product-item:nth-child(4n) {
        margin-right: 0;
    }

    .product-item img {
        display: block;
        width: 268px;
        height: 268px;
        margin: 0 auto;
        transition: all .2s;
    }

    .product-item h4 {
        margin: 20px 0 10px;
        font-weight: 700;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        font-size: 20px;
    }

    .product-item .goods-tags {
        margin: 0 0 8px;
        padding-bottom: 10px;
        height: 32px;
    }

    .goods-info {
        margin: 0 20px;
    }

    .txt {
        line-height: 19px;
        font-size: 19px;
        color: #444;
        margin: 0 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .price {
        line-height: 22px;
        font-size: 21px;
        font-weight: 700;
        color: #ff3838;
        margin: 8px 0 16px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }
</style>