<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="product">
            <div class="banner">
                <swiper v-if="swiperData.length" ref="mySwiper" :options="swiperOptions">
                    <swiper-slide v-for="item in swiperData">
                        <router-link :to="'/engineeringlist?id=' + item.data" target="_blank">
                            <img :src="item.slideshowUrl" alt="">
                        </router-link>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="main">
                <div class="left-menu">
                    <ul>
                        <li v-for="(item, i) in cateList">
                            <a :class="{active: idx == i}" href="javascript:;" @click="chooseMenu(i)">{{item.title}}</a>
                        </li>
                    </ul>
                </div>
                <div class="right-main">
                    <template v-if="list.length">
                        <div class="one-level" v-for="(item, idx) in list" :id="'type-' + idx">
                            <div class="breadcrumb">
                                <span>{{item.title}}</span>入选TOP{{item.total}}家
                            </div>
                            <div class="cate-item" v-for="ele in item.children">
                                <div class="cate-title">
                                    <a class="view-more" href="javascript:;" @click="viewMore(ele.id)">查看全部</a>
                                    <h4>{{ele.title}}</h4>
                                </div>
                                <div class="user-item" v-for="user in ele.children">
                                    <div class="name"><span>合作客户：{{user.customerName}}</span></div>
                                    <div class="img-wrap">
                                        <div class="img-box" v-for="img in user.defaultPicUrls">
                                            <img :src="img" alt="">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else class="breadcrumb">
                        暂无案例
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    export default {
        name: 'Engineering',
        components: {
            CommonHeader,
            BlackFooter,
            swiper, swiperSlide
        },
        data() {
            return {
                swiperOptions: {
                    loop: true,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                swiperData: [],
                idx: 0,
                cateList: [],
                total: 0,
                list: [],

                myTimer: -1
            }
        },
        mounted() {
            this.pageInit();
        },
        beforeRouteLeave(to, from, next) {
            let position = window.scrollY;
            localStorage.enTop = position;
            next();
        },
        beforeRouteEnter(to, from, next) {
            let name = from.name;
            if (name == 'EngineeringList') {
                next(vm => {
                    vm.isScroll = true;
                });
            }
            else {
                next();
            }
        },
        methods: {
            pageInit() {
                this.$api.getEngineeringAll().then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        Object.keys(data).forEach(key => {
                            let total = 0, list = [], title = '', oneId;
                            Object.keys(data[key]).forEach(k => {
                                let oC = data[key][k];
                                total += oC.length;
                                title = oC[0].cateLevelOne;
                                list.push({
                                    children: oC,
                                    title: oC[0].cateLevelTwo,
                                    id: oC[0].cateLevelTwoId
                                });
                            });
                            this.list.push({
                                title: title,
                                children: list,
                                total: total
                            });
                            this.cateList.push({
                                title: title
                            });
                        });
                        if (this.isScroll) {
                            this.isScroll = false;
                            this.$nextTick(() => {
                                let position = localStorage.enTop || 0;
                                window.scroll(0, position);
                                localStorage.enTop = 0;
                            })
                        }
                    }
                });

                this.$api.getSlideshow().then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.swiperData = data;
                    }
                });
            },
            viewMore(id) {
                this.$router.push({
                    path: '/engineeringlist?cateTwo=' + id
                })
            },
            chooseMenu(index) {
                this.idx = index;
                let jumpHeight = (document.getElementById('type-' + index)).offsetTop + 500;
                let top = document.documentElement.scrollTop;
                if (jumpHeight > top) {
                    let heigth = jumpHeight - top;
                    if (this.myTimer == -1) {
                        if (heigth > 10000) {
                            let num = Number((heigth / 80).toFixed(0));
                            this.myTimer = setInterval(() => {
                                top += num;
                                if (top >= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        } else {
                            this.myTimer = setInterval(() => {
                                top += 80;
                                if (top >= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        }
                    }
                } else {
                    let heigth = top - jumpHeight;
                    if (this.myTimer == -1) {
                        if (heigth > 10000) {
                            let num = Number((heigth / 80).toFixed(0));
                            this.myTimer = setInterval(() => {
                                top -= num;
                                if (top <= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        } else {
                            this.myTimer = setInterval(() => {
                                top -= 80;
                                if (top <= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        }
                    }
                }
            },
            getList(cateLevelOneId) {
                this.$api.getEngineering({ cateLevelOneId }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        let total = 0, list = [];
                        for (let key in data) {
                            let item = data[key];
                            total += item.length;
                            list.push({
                                children: item,
                                title: item[0].cateLevelTwo,
                                id: item[0].cateLevelTwoId
                            });
                        }
                        this.list = list;
                        this.total = total;
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .banner {
        height: 400px;
        overflow: hidden;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 400px;
    }

    .right-main {
        margin-left: 194px;
        min-height: calc(100vh - 100px);
        padding-bottom: 200px;
    }

    .breadcrumb {
        line-height: 36px;
        font-size: 36px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        text-align: center;
        color: #444;
        padding: 100px 0 24px;
        border-bottom: 1px solid #f1f1f1;
    }

    .cate-item {
        padding-bottom: 16px;
    }

    .cate-title {
        line-height: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #444;
        padding-left: 8px;
        border-left: 8px solid #34b8ff;
        margin: 24px 0;
    }

    .view-more {
        font-size: 16px;
        float: right;
        font-weight: normal;
        padding-right: 15px;
        background: url(~@/assets/icon_arrow_r.png) no-repeat right center;
    }

    .name {
        margin-bottom: 8px;
    }

    .name span {
        padding: 13px 16px;
        height: 47px;
        background: #ecf8ff;
        border-radius: 8px;
        display: inline-block;
        line-height: 1;
    }

    .img-wrap {
        overflow: hidden;
        margin-bottom: 24px;
    }

    .img-wrap .img-box {
        width: 592px;
        height: 592px;
        float: left;
        margin-right: 12px;
        border-radius: 30px;
        overflow: hidden;
    }

    .img-wrap .img-box:last-child {
        margin-right: 0;
    }
    .img-wrap .img-box:hover img{
        width: 110%;
        height: 110%;
    }

    .img-wrap img{
        width: 100%;
        height: 100%;
        transition: all .3s;
    }

    .left-menu {
        width: 150px;
        position: fixed;
        top: 194px;
        z-index: 999;
        left: 50%;
        margin-left: -695px;
    }

    .left-menu a {
        width: 150px;
        height: 43px;
        display: block;
        opacity: 0.82;
        background: rgba(42, 180, 255, 0.82);
        border-radius: 24px 0px 24px 0px;
        text-align: center;
        line-height: 43px;
        margin-bottom: 14px;
        color: #fff;
        font-size: 16px;
        overflow: hidden;
    }

    .left-menu a.active,
    .left-menu a:hover {
        background: #FFDE23;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        letter-spacing: 2px;
        opacity: 1;
    }
</style>