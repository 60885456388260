import axios from 'axios';
import { Toast } from 'vant';

const toast = msg => {
    Toast({
        message: msg,
        duration: 1500,
        forbidClick: true
    });
}

const errorHandle = (status) => {
    switch (status) {
        case 401://未登录
            localStorage.removeItem('token');
            break;

        case 403://token过期
            toast('登录过期，请重新登录');
            localStorage.removeItem('token');
            break;

        case 404://不存在
            toast('请求的资源不存在');
            break;

        default:
            toast('服务出错，请稍后重试');
            break;
    }
}

let instance = axios.create({timeout: 30000});

// 环境的切换
if (process.env.NODE_ENV == 'development') {
    instance.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'debug') {
    instance.defaults.baseURL = '';
} else if (process.env.NODE_ENV == 'production') {
    instance.defaults.baseURL = '';
}

//post请求头设置
instance.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

//请求拦截器
instance.interceptors.request.use(
    config => {
        if(!config.headers.token){
            const token = localStorage.getItem('token');
            token && (config.headers.token = token);
        }
        return config;
    },
    error => {
        return Promise.error(error);
    }
);


// 响应拦截器
instance.interceptors.response.use(
    response => {
        let config = response.config;
        if (response.status === 200) {
            let {success, msg, code} = response.data;
            let codeArr = ['00002', '10000', '10001', '10023', '10024'];
            if(!success){//接口返回失败处理
                if(codeArr.indexOf(code) > -1){//接口返回token失效则清除token
                    localStorage.removeItem('token');
                }
                else{
                    if(!config.noMsg){
                        toast(msg);
                    }
                }
            }
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);        
        }    
    },
    error => {
        const { response } = error;
        if(response){
            errorHandle(response.status);
            return Promise.reject(response);
        }
        else{//断网
            toast('网络连接错误');
            return Promise.reject(error);
        }
    }
);

export default instance;
