<template>
  <div id="app" v-cloak>
    <router-view></router-view>
  </div>
</template>

<script>

  export default {
    name: 'App',
    components: {
    }
  }
</script>

<style>
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #app {
    font-family: PingFang SC, PingFang SC-Regular;
  }

  .main {
    margin: 0 auto;
    position: relative;
    width: 1390px;
  }

  .page-wrap {
    padding: 38px 0 78px;
    text-align: right;
  }

  .page-wrap .el-pager li {
    background: #285cd6;
    border-radius: 2px;
  }

  .page-wrap .el-pagination.is-background .btn-next,
  .page-wrap .el-pagination.is-background .btn-prev,
  .page-wrap .el-pagination.is-background .el-pager li,
  .page-wrap .el-pagination span:not([class*=suffix]) {
    min-width: 40px;
    height: 40px;
    font-size: 16px;
    font-family: Source Han Sans CN, Source Han Sans CN-Regular;
    font-weight: 400;
    color: #666;
    line-height: 40px;
  }

  .page-wrap .el-pagination__total {
    margin: 0 0 0 22px;
  }

  .page-wrap .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #285cd6;
  }

  .goods-tags {
    padding-bottom: 7px;
    margin: 0 12px;
    border-bottom: 1px solid #f6f6f6;
    height: 26px;
    overflow: hidden;
  }

  .goods-tags span {
    height: 18px;
    border: 1px solid #ff3838;
    font-size: 10px;
    line-height: 16px;
    display: inline-block;
    color: #ff3838;
    padding: 0 4px;
    margin-right: 6px;
    margin-bottom: 4px;
  }
  .goods-tags span.new{
    color: #fff;
    background: #ff3838;
  }
  
  .search-more input{
    border-radius: 0;
    font-size: 16px;
  }

  ::placeholder{
    color: #c7c7c7;
  }

  .product{
    background-size: auto 400px !important;
  }
</style>