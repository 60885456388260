<template>
    <div class="wrap about-leader">
        <common-header></common-header>
        <div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
            <div class="main">
                <div class="right-main">
                    <div class="breadcrumb">
                        关于我们 > 全国基地
                    </div>
                    <div class="leader-img" v-if="leader.length">
                        <div v-for="item in leader" class="img-item">
                            <el-image :src="item.img" :preview-src-list="leaderImgs">
                            </el-image>
                            <div class="desc">{{item.remark}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';

    export default {
        name: 'AboutLeader',
        components: {
            CommonHeader,
            BlackFooter
        },
        data() {
            return {
                banner: '',
                leader: [],
                leaderImgs: [],
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.$api.getActivityData('PCBANNER').then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.banner = data.filter(item => item.model == 'BANNER_6')[0].img;
                    }
                });

                this.$api.getCaring({ size: 999 }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.leader = data.records;
                        this.leaderImgs = data.records.map(item => item.img);
                    }
                });

            }
        }
    }
</script>
<style scoped>
    .right-main {
        padding-top: 400px;
        min-height: calc(100vh - 100px);
        width: 1280px;
        margin: 0 auto;
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        padding: 68px 0 61px;
    }

    .leader-img {
        overflow: hidden;
    }

    .leader-img .img-item {
        margin-right: 40px;
        float: left;
        width: 620px;
        height: 494px;
    }

    .leader-img .img-item:nth-child(2n) {
        margin-right: 0;
    }

    .desc {
        padding: 20px 0;
        line-height: 21px;
        font-size: 14px;
        font-family: Source Han Sans CN, Source Han Sans CN-Regular;
        color: #444;
    }
</style>
<style>
    .about-leader {
        .leader-img img {
            width: 620px;
            height: 393px;
        }
    }
</style>