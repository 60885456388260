<template>
  <div class="wrap">
    <common-header></common-header>
    <home-main></home-main>
    <common-footer></common-footer>
  </div>
</template>
<script>
  import CommonHeader from '@/components/CommonHeader';
  import HomeMain from '@/components/HomeMain';
  import CommonFooter from '@/components/CommonFooter';
  export default {
    name: 'Home',
    components: {
      CommonHeader,
      HomeMain,
      CommonFooter,
    },
    data(){
        return {
        }
    },
    mounted(){
    },
    methods: {
    }
  }
</script>
<style scoped>
</style>