<template>
    <div class="">
        <div class="search-wrap">
            <label for="">关键词</label>
            <input class="input-sn" type="text" placeholder="商品型号/商品名称/商品描述/品牌" v-model="search.keyword" />
            <label for="">容量区间</label>
            <input class="input-min" type="text" placeholder="最低" v-model="search.literMinGt" />
            <input class="input-max" type="text" placeholder="最高" v-model="search.literMaxLt" />
			<span v-if="showMore" class="show-btn show-red">
				<img src="@/assets/icon_senior_r.png" alt="">高级
			</span>
			<span v-else class="show-btn" @click="showMore = true">
				<img src="@/assets/icon_senior.png" alt="">高级
			</span>
			<span v-if="showMore" class="show-btn show-blue" @click="showMore = false">
				<img src="@/assets/hide.png" alt="">收起
			</span>

            <button class="search-btn" @click="searchGoods">搜索</button>
            <div class="search-more" v-show="showMore">
                <label for="">空间尺寸</label>
                <input class="input-more" type="text" placeholder="长" v-model="search.length" />
                <span>x</span>
                <input class="input-more" type="text" placeholder="宽" v-model="search.width" />
                <span>x</span>
                <input class="input-more" type="text" placeholder="高" v-model="search.height" />
                <span>mm</span>
                <label class="energy" for="">能效等级</label>
                <el-select v-model="search.energyEfficiencyRate" placeholder="能效" filterable clearable
                    style="width: 225px;">
                    <el-option v-for="(item, idx) in energyList" :key="idx" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div v-if="pTitle" class="breadcrumb">
            <a href="javascript:;" @click="$router.back()">{{pTitle}}</a><span v-if="title"> > {{title}}</span><span
                v-else> > 全部商品</span>
        </div>
        <div class="sort">
            <span>排序方式</span>
            <a :class="setClass('goodsPriceOrderBy')" href="javascript:;" @click="setSort(1)">价格</a>
            <a :class="setClass('literOrderBy')" href="javascript:;" @click="setSort(2)">容量</a>
        </div>
        <div v-if="goodsList.length" class="goods-list">
            <div class="list-box">
                <router-link v-for="goods in goodsList" class="goods-item" :to="'/productdetail?id=' + goods.goodsId">
                    <img :src="goods.goodsImg" alt="">
                    <h4>{{goods.goodsType}}</h4>
                    <p class="txt">{{goods.goodsTitle}}</p>
                    <div class="goods-tags">
                        <span v-if="goods.brand"
                            :style="{color: goods.fontColor, background: goods.backgroundColor, borderColor: goods.borderColor }">{{goods.brand}}</span>
                        <span v-if="goods.recommendNew == 1" class="new">新品</span>
                    </div>
                    <p class="price">¥{{goods.goodsPrice.toFixed(2)}}</p>
                </router-link>
            </div>
            <div class="page-wrap">
                <pagination v-show="total>0" :total="total" background layout="prev, pager, next, total, jumper"
                    :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" />
            </div>
        </div>
        <div v-else class="no-goods">
            暂无商品
        </div>
    </div>
</template>
<script>
    import Pagination from '@/components/Pagination'
    export default {
        name: 'ProductList',
        components: {
            Pagination
        },
        data() {
            return {
                pid: this.$route.params.pid,
                cateList: [],
                goodsCateId: this.$route.query.id,
                pTitle: this.$route.query.ptitle,
                title: this.$route.query.title,
                listQuery: {
                    current: 1,
                    size: 15,
                },
                goodsList: [],
                total: 0,

                search: {
                    keyword: this.$route.query.keyword,
                    literMinGt: this.$route.query.literMinGt,
                    literMaxLt: this.$route.query.literMaxLt,
                    length: this.$route.query.length,
                    width: this.$route.query.width,
                    height: this.$route.query.height,
                    energyEfficiencyRate: this.$route.query.energyEfficiencyRate ? Number(this.$route.query.energyEfficiencyRate) : '',
                },
                energyList: [{ name: '一级', value: 1 }, { name: '二级', value: 2 }, { name: '三级', value: 3 }, { name: '四级', value: 4 }, { name: '五级', value: 5 }],

                goodsPriceOrderBy: '',
                literOrderBy: 0,

                showMore: false
            }
        },
        watch: {
            $route(to, from) {
                this.initParams();
                this.getList();
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.getList();
                if (this.search.length || this.search.width || this.search.height || this.search.energyEfficiencyRate) {
                    this.showMore = true;
                }
            },
            getList() {
                this.$api.getGoodsList({ ...this.listQuery, goodsCateId: this.goodsCateId, ...this.search, goodsPriceOrderBy: this.goodsPriceOrderBy, literOrderBy: this.literOrderBy }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.goodsList = data.rows;
                        this.total = data.total;
                    }
                });
            },
            initParams() {
                this.listQuery = {
                    current: 1,
                    size: 15,
                };
                this.pid = this.$route.params.pid;
                this.goodsCateId = this.$route.query.id;
                this.pTitle = this.$route.query.ptitle;
                this.title = this.$route.query.title;
                this.search = {
                    keyword: this.$route.query.keyword,
                    literMinGt: this.$route.query.literMinGt,
                    literMaxLt: this.$route.query.literMaxLt,
                    length: this.$route.query.length,
                    width: this.$route.query.width,
                    height: this.$route.query.height,
                    energyEfficiencyRate: this.$route.query.energyEfficiencyRate ? Number(this.$route.query.energyEfficiencyRate) : '',
                };
                this.goodsList = [];
            },
            searchGoods() {
                let reg = /(^[1-9]\d*$)/;
                let { literMinGt, literMaxLt, length, width, height } = this.search;
                if (literMinGt && !reg.test(literMinGt)) {
                    this.$message({
                        message: '容量区间需为正整数',
                        type: 'error'
                    });
                    return;
                }
                if (literMaxLt && !reg.test(literMaxLt)) {
                    this.$message({
                        message: '容量区间需为正整数',
                        type: 'error'
                    });
                    return;
                }
                if (length && !reg.test(length)) {
                    this.$message({
                        message: '长度需为正整数',
                        type: 'error'
                    });
                    return;
                }
                if (width && !reg.test(width)) {
                    this.$message({
                        message: '宽度需为正整数',
                        type: 'error'
                    });
                    return;
                }
                if (height && !reg.test(height)) {
                    this.$message({
                        message: '高度需为正整数',
                        type: 'error'
                    });
                    return;
                }
                this.$router.push({
                    path: '/product/' + this.pid + '/goods',
                    query: {
                        keyword: this.search.keyword,
                        literMinGt: this.search.literMinGt,
                        literMaxLt: this.search.literMaxLt,
                        length: this.search.length,
                        width: this.search.width,
                        height: this.search.height,
                        energyEfficiencyRate: this.search.energyEfficiencyRate
                    }
                }, () => { });
            },
            setSort(type){
                let result = '';
                if(type == 1){
                    this.literOrderBy = '';
                    if(this.goodsPriceOrderBy === ''){
                        result = 0;
                    }
                    if(this.goodsPriceOrderBy === 0){
                        result = 1;
                    }
                    if(this.goodsPriceOrderBy === 1){
                        result = 0;
                    }
                    this.goodsPriceOrderBy = result;
                }
                if(type == 2){
                    this.goodsPriceOrderBy = '';
                    if(this.literOrderBy === ''){
                        result = 0;
                    }
                    if(this.literOrderBy === 0){
                        result = 1;
                    }
                    if(this.literOrderBy === 1){
                        result = 0;
                    }
                    this.literOrderBy = result;
                }
                this.getList();
            },
            setClass(key){
                return {
                    on: this[key] !== '',
                    up: this[key] === 1
                }
            }
        }
    }
</script>
<style scoped>
    .search-wrap {
        width: 940px;
        background: #f6f6f6;
        border-radius: 8px;
        margin: 0 auto 48px;
        padding: 12px 22px;
        font-size: 16px;
        color: #666;
        line-height: 40px;
		position: relative;
    }

    .search-wrap label {
        width: 75px;
        display: inline-block;
    }

    .search-wrap input {
		margin-right: 26px;
        height: 38px;
        background: #ffffff;
        border: 1px solid #DCDFE6;
    }

    .search-wrap input.input-sn {
		width: 342px;
        padding-left: 38px;
        background: #fff url(~@/assets/icon_search.png) no-repeat 16px center;
    }

    .search-wrap input.input-min {
		width: 90px;
		margin-right: 6px;
        padding-left: 16px;
    }

    .search-wrap input.input-max {
		width: 90px;
        margin-right: 0;
        padding-left: 16px;
    }

    .search-btn {
		float: right;
		color: #fff;
		cursor: pointer;
		width: 87px;
		height: 40px;
		background: #34b8ff url(~@/assets/search.png) no-repeat 15px center;
		background-size: 16px;
		border: 1px solid #34b8ff;
		font-size: 16px;
		padding-left: 40px;
		text-align: left;
		line-height: 32px;
		border-radius: 6px;
    }

	.show-btn {
		float: right;
		cursor: pointer;
		margin-left: 26px;
	}
	.show-btn img{
		vertical-align: -2px;
		margin-right: 4px;
		width: 16px;
	}
	.show-red{
		color: #FD0000;
	}

	.show-blue{
		color: #34B8FF;
		position: absolute;
		right: 22px;
		bottom: 13px;
	}

    .search-more {
        margin-top: 20px;
    }

    .search-more .input-more {
        width: 90px;
        margin-right: 0;
        padding-left: 16px;
    }

    .search-more span {
        padding: 0 10px;
    }

    .energy {
        margin-left: 40px;
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        margin-bottom: 29px;
    }

    .sort {
        margin-bottom: 20px;
        text-align: right;
        line-height: 30px;
        font-size: 16px;
    }

    .sort span {
        margin-right: 18px;
    }

    .sort a {
        width: 102px;
        height: 32px;
        background: #fff url(~@/assets/icon_sort_down.png) no-repeat 66px center;
        border: 1px solid #c7c7c7;
        display: inline-block;
        text-align: center;
        padding-right: 16px;
    }

    .sort a.on {
        color: #50c1ff;
        border: 1px solid #50c1ff;
        background: #fff url(~@/assets/icon_sort_up_b.png) no-repeat 66px center;
    }
    .sort a.up{
        background: #fff url(~@/assets/icon_sort_down_b.png) no-repeat 66px center;
    }

    .goods-list {
        padding: 0 0 32px;
    }

    .list-box {
        margin-bottom: 22px;
    }

    .list-box::after {
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .goods-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 231px;
        float: left;
        margin-right: 10px;
        border: 1px solid #f6f6f6;
        margin-bottom: 10px;
        overflow: hidden;
    }

    .goods-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .goods-item:hover img {
        transform: scale(1.1);
    }

    .goods-item:hover .txt {
        color: #285CD6;
        font-weight: bold;
    }

    .goods-item:nth-child(5n) {
        margin-right: 0;
    }

    .goods-item img {
        display: block;
        width: 229px;
        height: 229px;
        transition: all .2s;
    }

    .goods-item h4 {
        margin: 16px 12px 8px;
        line-height: 17px;
        font-size: 16px;
        font-weight: 700;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .txt {
        line-height: 16px;
        font-size: 16px;
        color: #444;
        margin: 0 12px 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .price {
        line-height: 18px;
        font-size: 18px;
        font-weight: 700;
        color: #ff3838;
        margin: 8px 12px 16px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }
</style>