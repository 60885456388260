<template>
  <div class="wrap home">
    <div class="banner">
      <swiper v-if="swiperData.length" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="item in swiperData">
          <a v-if="item.type == 0" href="javascript:;"><img :src="item.img" alt=""></a>
          <a v-if="item.type == 1 || item.type == 5 || item.type == 6" :href="item.data" target="_blank"><img
              :src="item.img" alt=""></a>
          <router-link v-if="item.type == 2" :to="'/recommend?activityId=' + item.activityId" target="_blank"><img
              :src="item.img" alt=""></router-link>
          <template v-if="item.type == 3">
            <router-link v-if="item.data" :to="'/productdetail?id=' + item.data" target="_blank"><img :src="item.img"
                alt=""></router-link>
            <a v-else href="javascript:;"><img :src="item.img" alt=""></a>
          </template>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  export default {
    name: 'HomeMain',
    components: {
      swiper, swiperSlide
    },
    props: {
    },
    data() {
      return {
        swiperOptions: {
          loop: true,
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          }
        },
        swiperData: []
      }
    },
    mounted() {
      this.pageInit();
    },
    methods: {
      pageInit() {
        this.$api.getActivityData('PCHOME').then(res => {
          let { data, success } = res.data;
          if (success) {
            this.swiperData = data;
          }
        });
      }
    }
  }
</script>
<style>
  .home .banner {
    height: calc(100vh - 100px);
    overflow: hidden;
  }

  .home .swiper-container {
    width: 100%;
    height: 100%;
  }

  .home .swiper-slide {
    background-position: center;
    background-repeat: no-repeat;
  }

  .home .swiper-slide img {
    display: block;
    width: 100%;
    position: absolute;
    max-height: 100%;
  }

  .home .swiper-pagination-fraction,
  .home .swiper-pagination-custom,
  .home .swiper-container-horizontal>.swiper-pagination-bullets {
    bottom: 77px;
  }

  .home .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 8px;
    width: 97px;
    height: 7px;
    border-radius: 0;
    background: #fff;
  }

  .home .swiper-pagination-bullet-active {
    background: #4b4b4b;
  }

  .home .swiper-button-prev,
  .home .swiper-button-next {
    width: 125px;
    height: 125px;
    margin-top: -63px;
    background-image: url(~@/assets/icon_arrow_b_l.png);
    background-size: 100%;
    left: 110px;
    display: none;
  }

  .home .swiper-button-next {
    background-image: url(~@/assets/icon_arrow_b_r.png);
    left: auto;
    right: 110px;
  }

  .home .swiper-container:hover .swiper-button-prev,
  .home .swiper-container:hover .swiper-button-next {
    display: block;
  }

  .home .swiper-button-prev.swiper-button-disabled,
  .home .swiper-button-next.swiper-button-disabled {
    pointer-events: inherit;
  }

  @media screen and (max-width: 1650px) {
    .home .swiper-slide img {
      transform: translateY(0);
      position: absolute;
      top: 0;
    }
  }
</style>