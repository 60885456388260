<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
            <div class="main">
                <div class="left-menu">
                    <ul>
                        <li v-for="(item, i) in cateList">
                            <a :class="{active: idx == i}" href="javascript:;" @click="chooseMenu(i)">{{item.title}}</a>
                        </li>
                    </ul>
                </div>
                <div class="right-main">
                    <div class="breadcrumb">
                        精品鉴赏 <span>入选{{total}}款历史经典产品</span>
                    </div>
                    <div class="cate-list">
                        <a v-for="(item, i) in cateList" @click="chooseMenu(i)"
                            href="javascript:;">{{item.title}}（{{item.total}}款）</a>
                    </div>
                    <div v-if="cateList.length" v-for="(cate, index) in cateList" class="product-list"
                        :id="'cate' + index">
                        <div class="level-title">
                            <span v-if="cate.total > cate.boothsCount" class="more" @click.stop="jumpList(cate)">查看全部</span>
                            <span class="cate-title">{{cate.title}}</span>
                            <span class="new-total">共{{cate.total}}款产品</span>
                        </div>
                        <div v-if="cate.boothsCount == 5" class="product-five">
                            <router-link v-for="(item, idx) in cate.goodslVOS" class="product-item"
                                :class="'five' + idx" :to="'/boutiquedetail?id=' + item.goodsId" v-if="idx < 5">
                                <img :src="item.goodsImg" alt="">
                                <h4>{{item.goodsTitle || '-'}}</h4>
                                <p class="txt">{{item.goodsType}}</p>
                                <p class="price">¥{{item.goodsPrice.toFixed(2)}}</p>
                            </router-link>
                        </div>
                        <div v-else-if="cate.boothsCount == 4" class="list-box product-four">
                            <router-link v-for="(item, idx) in cate.goodslVOS" class="product-item"
                                :class="'four' + idx" :to="'/boutiquedetail?id=' + item.goodsId" v-if="idx < 4">
                                <img :src="item.goodsImg" alt="">
                                <h4>{{item.goodsTitle}}</h4>
                                <p class="txt">{{item.goodsType}}</p>
                            </router-link>
                        </div>
                        <div v-else class="list-box product-common">
                            <router-link v-for="(item, idx) in cate.goodslVOS" class="product-item"
                                :to="'/boutiquedetail?id=' + item.goodsId" v-if="idx < cate.boothsCount">
                                <img :src="item.goodsImg" alt="">
                                <h4>{{item.goodsTitle}}</h4>
                                <p class="txt">{{item.goodsType}}</p>
                            </router-link>
                        </div>
                    </div>
                    <div v-else class="no-goods">
                        暂无商品
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    export default {
        name: 'Boutique',
        components: {
            CommonHeader,
            BlackFooter,
        },
        data() {
            return {
                idx: 0,
                banner: '',
                cateList: [],
                total: 0,
                myTimer: -1
            }
        },
        mounted() {
            this.getList();
            window.addEventListener("scroll", this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener("scroll", this.handleScroll);
        },
        beforeRouteLeave(to, from, next) {
            let position = window.scrollY;
            localStorage.newTop = position;
            next();
        },
        beforeRouteEnter(to, from, next) {
            let name = from.name;
            if (name == 'BoutiqueDetail' || name == 'BoutiqueList') {
                next(vm => {
                    vm.isScroll = true;
                });
            }
            else {
                next();
            }
        },
        methods: {
            handleScroll() {
                let scrollPosition =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop ||
                    0;
                for (let i = 0; i < this.cateList.length; i++) {
                    let sectionTop = document.getElementById('cate' + i).offsetTop;
                    let sectionHeight = document.getElementById(
                        'cate' + i
                    ).offsetHeight;
                    if (
                        scrollPosition >= sectionTop - sectionHeight / 3 &&
                        scrollPosition < sectionTop + sectionHeight - sectionHeight / 3
                    ) {
                        this.idx = i;
                    }
                }
            },
            getList() {
                this.$api.getCateGoods({ belong: 1 }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.cateList = this.initData(data);
                        if (this.isScroll) {
                            this.isScroll = false;
                            this.$nextTick(() => {
                                let position = localStorage.newTop || 0;
                                window.scroll(0, position);
                                localStorage.newTop = 0;
                            })
                        }
                    }
                });

                this.$api.getActivityData('PCBANNER').then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.banner = data.filter(item => item.model == 'BANNER_5')[0].img;
                    }
                });
            },
            initData(data) {
                data.sort((a, b) => {
                    return a.sequence - b.sequence;
                });
                data.forEach((ele, idx) => {
                    this.total += ele.goodslVOS.length;
                    ele.total = ele.goodslVOS.length;
                });
                return data;
            },
            jumpList(cate) {
                this.$router.push({
                    path: '/boutique/list?type=2&id=' + cate.goodsFlagId + '&title=' + cate.title
                })
            },
            chooseMenu(index) {
                this.idx = index;
                let jumpHeight = (document.getElementById('cate' + index)).offsetTop + 80;
                let top = document.documentElement.scrollTop;
                if (jumpHeight > top) {
                    let heigth = jumpHeight - top;
                    if (this.myTimer == -1) {
                        if (heigth > 10000) {
                            let num = Number((heigth / 80).toFixed(0));
                            this.myTimer = setInterval(() => {
                                top += num;
                                if (top >= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        } else {
                            this.myTimer = setInterval(() => {
                                top += 80;
                                if (top >= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        }
                    }
                } else {
                    let heigth = top - jumpHeight;
                    if (this.myTimer == -1) {
                        if (heigth > 10000) {
                            let num = Number((heigth / 80).toFixed(0));
                            this.myTimer = setInterval(() => {
                                top -= num;
                                if (top <= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        } else {
                            this.myTimer = setInterval(() => {
                                top -= 80;
                                if (top <= jumpHeight) {
                                    top = jumpHeight;
                                    window.clearInterval(this.myTimer);
                                    this.myTimer = -1;
                                }
                                window.scrollTo(0, top);
                            }, 10);
                        }
                    }
                }
            },
        }
    }
</script>
<style scoped>
    .right-main {
        margin-left: 194px;
        padding-top: 432px;
        min-height: calc(100vh - 100px);
        padding-bottom: 80px;
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        margin-bottom: 24px;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 25px;
    }

    .breadcrumb span {
        font-weight: normal;
    }

    .cate-list {
        border: 1px dashed #f1f1f1;
        border-radius: 16px;
        font-size: 20px;
        color: #444;
        padding: 16px 32px;
        margin-bottom: 32px;
        margin-left: 30px;
    }

    .cate-list a {
        font-weight: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 44px;
    }

    .product-list {
        margin-left: 30px;
        margin-bottom: 40px;
    }

    .level-title {
        height: 20px;
        border-left: 8px solid #34b8ff;
        margin-bottom: 24px;
        line-height: 20px;
        padding: 0 0 0 8px;
        position: relative;
        font-size: 20px;
        cursor: pointer;
    }

    .cate-title {
        font-weight: bold;
        color: #444;
    }

    .new-total {
        margin-left: 60px;
    }

    .more {
        float: right;
        font-size: 16px;
        color: #444;
        padding-right: 16px;
        background: url(~@/assets/icon_arrow_r.png) no-repeat right center;
    }

    .list-box {
        text-align: center;
    }

    .list-box::after {
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .product-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 380px;
        height: 350px;
        float: left;
        margin-right: 8px;
        border: 1px solid #f6f6f6;
        margin-bottom: 10px;
        text-align: center;
        overflow: hidden;
    }

    .product-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .product-item:hover img {
        transform: scale(1.1);
    }

    .product-item:hover h4 {
        color: #285CD6;
    }

    .product-item:first-child {
        margin-left: 0;
    }

    .product-item:last-child {
        margin-right: 0;
    }

    .product-item img {
        display: block;
        width: 236px;
        height: 236px;
        margin: 0 auto;
        transition: all .2s;
    }

    .product-item h4 {
        margin: 24px 0 8px;
        font-weight: 700;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        font-size: 20px;
    }

    .txt {
        line-height: 16px;
        font-size: 16px;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
    }

    .price {
        line-height: 18px;
        font-size: 18px;
        font-weight: 700;
        color: #ff3838;
    }

    .product-five {
        height: 530px;
        position: relative;
    }

    .product-five .product-item {
        background: #ffffff;
        border: 1px solid #f6f6f6;
        overflow: hidden;
        position: absolute;
    }

    .five0 {
        left: 0;
        top: 0;
        width: 458px;
        height: 530px;
    }

    .five0 img {
        width: 376px;
        height: 376px;
    }

    .five1,
    .five2,
    .five3,
    .five4 {
        width: 344px;
        height: 261px;
    }

    .five1 {
        top: 0;
        left: 467px;
    }

    .five2 {
        top: 269px;
        left: 467px;
    }

    .five3 {
        top: 0;
        left: 819px;
    }

    .five4 {
        top: 269px;
        left: 819px;
    }

    .five1 img,
    .five2 img,
    .five3 img,
    .five4 img {
        width: 177px;
        height: 177px;
        margin: 16px auto 0;
    }

    .five1 h4,
    .five2 h4,
    .five3 h4,
    .five4 h4 {
        margin-top: 7px;
        margin-bottom: 11px;
    }

    .five1 .txt,
    .five2 .txt,
    .five3 .txt,
    .five4 .txt {
        font-size: 14px;
    }

    .five1 .price,
    .five2 .price,
    .five3 .price,
    .five4 .price {
        display: none;
    }

    .product-four {
        text-align: left;
    }

    .product-four .product-item {
        width: 575px;
        height: 435px;
    }

    .product-four .product-item img {
        width: 295px;
        height: 295px;
        margin: 37px auto 24px;
    }

    .product-four .txt,
    .product-common .txt {
        font-size: 14px;
    }

    .product-common img {
        margin-top: 28px;
    }

    .product-common h4 {
        margin-top: 14px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }

    .left-menu {
        width: 150px;
        position: fixed;
        top: 194px;
        z-index: 999;
        left: 50%;
        margin-left: -695px;
    }

    .left-menu a {
        width: 150px;
        height: 43px;
        display: block;
        opacity: 0.82;
        background: rgba(42, 180, 255, 0.82);
        border-radius: 24px 0px 24px 0px;
        text-align: center;
        line-height: 43px;
        margin-bottom: 14px;
        color: #fff;
        font-size: 16px;
        overflow: hidden;
    }

    .left-menu a.active,
    .left-menu a:hover {
        background: #FFDE23;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        letter-spacing: 2px;
        opacity: 1;
    }
</style>