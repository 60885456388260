<template>
    <div class="">
        <div v-if="videoList.length" class="product-list">
            <ul class="list-box">
                <li v-for="item in videoList" class="product-item">
                    <img :src="item.img" alt="">
                    <h4>{{item.goodsVideoTitle}}</h4>
                    <a class="play" href="javascript:;" @click="videoPlay(item)"></a>
                </li>
            </ul>
            <div class="page-wrap">
                <pagination v-show="total>0" :total="total" background layout="prev, pager, next, total, jumper"
                    :page.sync="listQuery.current" :limit.sync="listQuery.size" @pagination="getList" />
            </div>
        </div>
        <div v-else class="no-goods">
            暂无视频
        </div>
        <div v-show="showVideo" class="video-layer">
            <div class="video-main">
                <a class="close" href="javascript:;" @click="closeVideo"></a>
                <video v-if="currentUrl && currentType == 2" :src="currentUrl" autoplay controls></video>
                <div v-if="currentType == 3" id="player"></div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from '@/components/Pagination'
    export default {
        name: 'Video',
        props: [
            'menu'
        ],
        components: {
            Pagination
        },
        data() {
            return {
                id: this.$route.query.id,

                videoList: [],
                listQuery: {
                    current: 1,
                    size: 15,
                },
                currentUrl: '',
                currentType: 2,
                showVideo: false,
                player: null
            }
        },
        watch: {
            $route(to, from) {
                this.id = to.query.id;
                this.listQuery.current = 1;
                this.pageInit();
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.getList();
            },
            getList() {
                let opt = {
                    ...this.listQuery,
                    goodsCateId: this.id || this.menu.goodsCateId
                }
                this.$api.getVideoList(opt).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.videoList = data.rows;
                        this.total = data.total;
                    }
                });
            },
            videoPlay(item) {
                this.showVideo = true;
                this.currentUrl = item.goodsVideoValue;
                this.currentType = item.goodsVideoType;
                this.$nextTick(() => {
                    if (this.currentType == 3) {
                        this.player && this.player.destroy();
                        this.player = polyvPlayer({
                            wrap: "#player",
                            width: 640,
                            height: 480,
                            vid: this.currentUrl
                        });
                    }
                })
            },
            closeVideo() {
                this.showVideo = false;
                this.currentUrl = '';
            }
        }
    }
</script>
<style scoped>
    .product-list {
        padding: 0 0 32px;
    }

    .list-box {
        margin-bottom: 22px;
    }
    .list-box::after{
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .product-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 231px;
        float: left;
        margin-right: 10px;
        border: 1px solid #f6f6f6;
        margin-bottom: 10px;
        position: relative;
    }

    .product-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .play {
        background: url(~@/assets/icon_play.png) no-repeat;
        width: 56px;
        height: 56px;
        display: block;
        position: absolute;
        left: 87px;
        top: 86px;
    }

    .product-item:nth-child(5n) {
        margin-right: 0;
    }

    .product-item img {
        display: block;
        width: 229px;
        height: 229px;
        background: #000;
    }

    .product-item h4 {
        margin: 10px 16px 16px;
        line-height: 24px;
        font-size: 18px;
        font-weight: normal;
        color: #444;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        height: 48px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }

    .video-layer {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .6);
        z-index: 999;
    }

    .video-main {
        width: 640px;
        height: 480px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -320px;
        margin-top: -240px;
    }

    .video-main video {
        width: 100%;
        height: 100%;
        background: #000;
    }

    .close {
        position: absolute;
        right: -32px;
        top: -32px;
        width: 32px;
        height: 32px;
        display: block;
        background: url(~@/assets/icon_dele.png) no-repeat;
    }
</style>