<template>
	<div class="wrap">
		<div class="search-wrap">
			<label for="">关键词</label>
			<input class="input-sn" type="text" placeholder="商品型号/商品名称/商品描述/品牌" v-model="keyword" />
			<label for="">容量区间</label>
			<input class="input-min" type="text" placeholder="最低" v-model="literMinGt" />
			<input class="input-max" type="text" placeholder="最高" v-model="literMaxLt" />
			<span v-if="showMore" class="show-btn show-red">
				<img src="@/assets/icon_senior_r.png" alt="">高级
			</span>
			<span v-else class="show-btn" @click="showMore = true">
				<img src="@/assets/icon_senior.png" alt="">高级
			</span>
			<span v-if="showMore" class="show-btn show-blue" @click="showMore = false">
				<img src="@/assets/hide.png" alt="">收起
			</span>

			<button class="search-btn" @click="searchGoods">搜索</button>
			<div class="search-more" v-show="showMore">
				<label for="">空间尺寸</label>
				<input class="input-more" type="text" placeholder="长" v-model="length" />
				<span>x</span>
				<input class="input-more" type="text" placeholder="宽" v-model="width" />
				<span>x</span>
				<input class="input-more" type="text" placeholder="高" v-model="height" />
				<span>mm</span>
				<label class="energy" for="">能效等级</label>
				<el-select v-model="energyEfficiencyRate" placeholder="能效" filterable clearable style="width: 225px;">
					<el-option v-for="(item, idx) in energyList" :key="idx" :label="item.name" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
		<div v-if="areaList.length" class="product-list">
			<div v-for="item in areaList">
				<h3 v-if="item.children && item.children.length" class="h3-title"><a class="more" v-if="item.type != 3"
						href="javascript:;" @click="jumpGoodsAll(item)">全部商品</a><span>{{item.title}}</span></h3>
				<div class="list-box">
					<div v-for="child in item.children" class="product-item" @click="jumpPage(child, item)">
						<img :src="child.img" alt="">
						<h4>{{child.title}}</h4>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-goods">
			暂无商品
		</div>
	</div>
</template>
<script>
	export default {
		name: 'Product',
		props: [
			'menu'
		],
		components: {
		},
		data() {
			return {
				isScroll: false,
				id: this.$route.params.pid,
				areaList: [],

				keyword: '',
				literMinGt: '',
				literMaxLt: '',
				length: '',
				width: '',
				height: '',
				energyEfficiencyRate: '',
				energyList: [{ name: '一级', value: 1 }, { name: '二级', value: 2 }, { name: '三级', value: 3 }, { name: '四级', value: 4 }, { name: '五级', value: 5 }],

				showMore: false
			}
		},
		mounted() {
			this.pageInit();
		},
		beforeRouteLeave(to, from, next) {
			let position = window.scrollY;
			localStorage.productTop = position;
			next();
		},
		beforeRouteEnter(to, from, next) {
			let pid = to.params.pid;
			let id = from.params.pid;
			let name = from.name;
			if (name == 'ProductList' && id == pid) {
				next(vm => {
					vm.isScroll = true;
				});
			}
			else if (name == 'Recommend') {
				next(vm => {
					vm.isScroll = true;
				});
			}
			else {
				next();
			}
		},
		methods: {
			pageInit() {
				let opt = {
					type: '1,3',
					cateId1: this.id
				};
				if (!this.id) {
					opt.cateId1 = this.menu.goodsCateId;
				}
				this.$api.getCateData(opt).then(res => {
					let { data, success } = res.data;
					if (success) {
						this.areaList = data[0].children;
						if (this.isScroll) {
							this.isScroll = false;
							this.$nextTick(() => {
								let position = localStorage.productTop || 0;
								window.scroll(0, position);
								localStorage.productTop = 0;
							})
						}
					}
				});
			},
			searchGoods() {
				let reg = /(^[1-9]\d*$)/;
				if (this.literMinGt && !reg.test(this.literMinGt)) {
					this.$message({
						message: '容量区间需为正整数',
						type: 'error'
					});
					return;
				}
				if (this.literMaxLt && !reg.test(this.literMaxLt)) {
					this.$message({
						message: '容量区间需为正整数',
						type: 'error'
					});
					return;
				}
				if (this.length && !reg.test(this.length)) {
					this.$message({
						message: '长度需为正整数',
						type: 'error'
					});
					return;
				}
				if (this.width && !reg.test(this.width)) {
					this.$message({
						message: '宽度需为正整数',
						type: 'error'
					});
					return;
				}
				if (this.height && !reg.test(this.height)) {
					this.$message({
						message: '高度需为正整数',
						type: 'error'
					});
					return;
				}

				let pid = this.id || this.menu.goodsCateId;
				this.$router.push({
					path: '/product/' + pid + '/goods?keyword=' + this.keyword + '&literMinGt=' + this.literMinGt + '&literMaxLt=' + this.literMaxLt + '&length=' + this.length + '&width=' + this.width + '&height=' + this.height + '&energyEfficiencyRate=' + this.energyEfficiencyRate
				});
			},
			jumpPage(data, parent) {
				if (data.url) {
					window.open(data.url, '_blank');
				}
				else {
					//星星推荐跳转列表
					if (data.type == 3) {
						this.$router.push({
							path: '/recommend?id=' + data.goodsCateId + '&ptitle=' + parent.title
						});
					}
					else {
						let pid = this.id || this.menu.goodsCateId;
						this.$router.push({
							path: '/product/' + pid + '/goods?id=' + data.goodsCateId + '&ptitle=' + parent.title + '&title=' + data.title
						});
					}
				}
			},
			jumpGoodsAll(data) {
				let pid = this.id || this.menu.goodsCateId;
				this.$router.push({
					path: '/product/' + pid + '/goods?id=' + data.goodsCateId + '&ptitle=' + data.title
				});
			},
		}
	}
</script>
<style scoped>
	.search-wrap {
		width: 940px;
		background: #f6f6f6;
		border-radius: 8px;
		margin: 0 auto;
		padding: 12px 22px;
		font-size: 16px;
		color: #666;
		line-height: 40px;
		position: relative;
	}

	.search-wrap label {
		width: 75px;
		display: inline-block;
	}

	.search-wrap input {
		margin-right: 26px;
		height: 38px;
		background: #ffffff;
		border: 1px solid #DCDFE6;
	}

	.search-wrap input.input-sn {
		width: 342px;
		padding-left: 38px;
		background: #fff url(~@/assets/icon_search.png) no-repeat 16px center;
	}

	.search-wrap input.input-min {
		width: 90px;
		margin-right: 6px;
		padding-left: 16px;
	}

	.search-wrap input.input-max {
		width: 90px;
		margin-right: 0;
		padding-left: 16px;
	}

	.search-btn {
		float: right;
		color: #fff;
		cursor: pointer;
		width: 87px;
		height: 40px;
		background: #34b8ff url(~@/assets/search.png) no-repeat 15px center;
		background-size: 16px;
		border: 1px solid #34b8ff;
		font-size: 16px;
		padding-left: 40px;
		text-align: left;
		line-height: 32px;
		border-radius: 6px;
	}

	.show-btn {
		float: right;
		cursor: pointer;
		margin-left: 26px;
	}
	.show-btn img{
		vertical-align: -2px;
		margin-right: 4px;
		width: 16px;
	}
	.show-red{
		color: #FD0000;
	}

	.show-blue{
		color: #34B8FF;
		position: absolute;
		right: 22px;
		bottom: 13px;
	}

	.search-more {
		margin-top: 20px;
	}

	.search-more .input-more {
		width: 90px;
		margin-right: 0;
		padding-left: 16px;
	}

	.search-more span {
		padding: 0 10px;
	}

	.energy {
		margin-left: 40px;
	}

	.product-list {
		padding: 48px 0 32px;
	}

	.h3-title {
		line-height: 20px;
		font-weight: 700;
		color: #444;
		margin-bottom: 32px;
		font-size: 24px;
		line-height: 1;
		border-bottom: 1px solid #c7c7c7;
	}

	.h3-title span {
		display: inline-block;
		padding-bottom: 8px;
	}

	.more {
		float: right;
		font-style: normal;
		font-size: 16px;
		font-weight: 400;
		padding-right: 16px;
		background: url(~@/assets/icon_arrow_r.png) no-repeat right center;
	}

	.list-box {
		margin-bottom: 22px;
	}

	.list-box::after {
		content: "";
		display: block;
		overflow: hidden;
		height: 0;
		clear: both;
	}

	.product-item {
		line-height: 18px;
		font-size: 18px;
		font-weight: 400;
		text-align: center;
		color: #444;
		width: 275px;
		float: left;
		margin-right: 32px;
		margin-bottom: 10px;
		cursor: pointer;
		padding-bottom: 20px;
		overflow: hidden;
	}

	.product-item:hover {
		box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
	}

	.product-item:nth-child(4n) {
		margin-right: 0;
	}

	.product-item:hover h4 {
		color: #285CD6;
	}

	.product-item:hover img {
		transform: scale(1.1);
	}

	.product-item img {
		display: block;
		width: 275px;
		height: 275px;
		border-radius: 8px;
		transition: all .2s;
	}

	.product-item h4 {
		margin-top: 16px;
	}

	.no-goods {
		line-height: 20px;
		font-size: 20px;
		text-align: center;
		color: #666;
		padding-top: 43px;
	}
</style>