import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/views/Home'
import ProductMain from '@/views/ProductMain'
import Product from '@/views/Product'
import ProductList from '@/views/ProductList'
import ProductDetail from '@/views/ProductDetail'
import Recommend from '@/views/Recommend'
import Video from '@/views/Video'
import New from '@/views/New'
import NewList from '@/views/NewList'
import Boutique from '@/views/Boutique'
import Engineering from '@/views/Engineering'
import EngineeringList from '@/views/EngineeringList'
import EngineeringGoods from '@/views/EngineeringGoods'
import News from '@/views/News'
import NewsDetail from '@/views/NewsDetail'
import About from '@/views/About'
import AboutLeader from '@/views/AboutLeader'
Vue.use(Router)

export default new Router({
  base: '/',
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      meta: {
        title: '星云智数-首页'
      }
    },
    {
      path: '/product',
      name: 'ProductMain',
      component: ProductMain,
      meta: {
        title: '商品货架'
      },
      children: [
        {
          path: '/product',
          name: 'Product',
          component: Product,
          meta: {
            title: '商品货架'
          }
        },
        {
          path: 'list/:pid',
          name: 'ProductCate',
          component: Product,
          meta: {
            title: '商品货架'
          }
        },
        {
          path: ':pid/goods',
          name: 'ProductList',
          component: ProductList,
          meta: {
            title: '商品货架'
          }
        },
      ]
    },
    {
      path: '/productdetail',
      name: 'ProductDetail',
      component: ProductDetail,
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/recommend',
      name: 'Recommend',
      component: Recommend,
      meta: {
        title: '星星推荐'
      }
    },
    {
      path: '/video',
      name: 'VideoMain',
      component: ProductMain,
      meta: {
        title: '品宣视频',
        type: 2
      },
      children: [
        {
          path: '/video',
          name: 'Video',
          component: Video,
          meta: {
            title: '品宣视频',
            type: 2
          }
        },
      ]
    },
    {
      path: '/newrecommend',
      name: 'New',
      component: New,
      meta: {
        title: '新品发布'
      }
    },
    {
      path: '/newrecommend/list',
      name: 'NewList',
      component: NewList,
      meta: {
        title: '新品发布',
      }
    },
    {
      path: '/newrecommenddetail',
      name: 'NewDetail',
      component: ProductDetail,
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/boutique',
      name: 'Boutique',
      component: Boutique,
      meta: {
        title: '精品鉴赏'
      }
    },
    {
      path: '/boutique/list',
      name: 'BoutiqueList',
      component: NewList,
      meta: {
        title: '精品鉴赏',
      }
    },
    {
      path: '/boutiquedetail',
      name: 'BoutiqueDetail',
      component: ProductDetail,
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/engineering',
      name: 'Engineering',
      component: Engineering,
      meta: {
        title: '工程案例'
      }
    },
    {
      path: '/engineeringgoods',
      name: 'EngineeringGoods',
      component: EngineeringGoods,
      meta: {
        title: '工程案例'
      }
    },
    {
      path: '/engineeringlist',
      name: 'EngineeringList',
      component: EngineeringList,
      meta: {
        title: '工程案例'
      }
    },
    {
      path: '/engineeringdetail',
      name: 'EngineeringDetail',
      component: ProductDetail,
      meta: {
        title: '商品详情'
      }
    },
    {
      path: '/news',
      name: 'News',
      component: News,
      meta: {
        title: '新闻中心'
      }
    },
    {
      path: '/newsdetail',
      name: 'NewsDetail',
      component: NewsDetail,
      meta: {
        title: '新闻中心'
      }
    },
    {
      path: '/about',
      name: 'About',
      component: About,
      meta: {
        title: '关于我们'
      }
    },
    {
      path: '/aboutleader',
      name: 'AboutLeader',
      component: AboutLeader,
      meta: {
        title: '关于我们'
      }
    },
  ]
})
