import axios from '@/utils/http';
import qs from 'qs';

//请求示例
// const get1 = data => axios.get(url, { params: data });
// const post1 = data => axios.post(url, qs.stringify(data));
// const post2 = data => axios.post(url, data);
// const post3 = data => axios.post(url, data, {headers: {'Content-Type': 'application/json'}});

let xygUrl = '/xygms-pc'
if(process.env.NODE_ENV === 'development'){
    xygUrl = ''
}

const setStatistics = data => axios.post(xygUrl + '/xygms/dataStatistics', {}, {headers: {'Content-Type': 'application/json', userAgent: data} });

const getActivityData = type => axios.get(xygUrl + '/api/activity/p/' + type);
const getActivityDetail = id => axios.get(xygUrl + '/api/activity/p/info/' + id);
const getActivityGoods = data => axios.get(xygUrl + '/api/activity/p/goodsList', { params: data });

const getCateData = data => axios.get(xygUrl + '/api/cate/p/tree/' + data.type, {params: data});
const getCateDetail = id => axios.get(xygUrl + '/api/cate/p/' + id);
const getGoodsList = data => axios.get(xygUrl + '/api/goods/p?isshow=1', { params: data });
const getGoodsDetail = id => axios.get(xygUrl + '/api/goods/p/' + id);

const getCateGoods = data => axios.get(xygUrl + '/api/goods/label', {params: data});
const getNewGoodsList = data => axios.get(xygUrl + '/api/goods/labelGoods', {params: data});

const getVideoList = data => axios.get(xygUrl + '/api/video/p', { params: data });

const getArticleList = data => axios.get(xygUrl + '/api/article/p', { params: data });
const getArticleDetail = id => axios.get(xygUrl + '/api/article/p/' + id);

const getCaring = data => axios.get(xygUrl + '/api/caring', { params: data });
const getHonor = data => axios.get(xygUrl + '/api/honor', { params: data });

const getSlideshow = data => axios.get(xygUrl + '/api/slideshow', { params: data });
const getEngineering = data => axios.get(xygUrl + '/api/mineEngineering/p', { params: data });
const getEngineeringAll = data => axios.get(xygUrl + '/api/mineEngineering/all', { params: data });
const getEngineeringCate = data => axios.get(xygUrl + '/api/engineeringCate/list', { params: data });


export default {
    setStatistics,
    
    getActivityData,
    getActivityDetail,
    getActivityGoods,

    getCateData,
    getCateDetail,
    getGoodsList,
    getGoodsDetail,

    getCateGoods,
    getNewGoodsList,

    getVideoList,

    getArticleList,
    getArticleDetail,

    getCaring,
    getHonor,

    getSlideshow,
    getEngineering,
    getEngineeringCate,
    getEngineeringAll,
}