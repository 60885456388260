<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="product" :style="'background: url(' + banner + ') no-repeat top center;'">
            <div class="main">
                <div class="right-main">
                    <div class="breadcrumb">
                        <strong>新品发布</strong>共{{total}}款产品
                    </div>
                    <h2 class="h2-title">新品发布展示</h2>
                    <div v-if="cateList.length" v-for="(cate, index) in cateList" class="product-list">
                        <div class="level-title" @click="showGoods(index)" :class="{show: cate.show}">
                            <span v-if="cate.total > cate.boothsCount && cate.show" class="more"
                                @click.stop="jumpPage('/newrecommend/list?type=1&id=' + cate.goodsFlagId + '&title=' + cate.title, index)">更多</span>
                            <span class="cate-title">{{cate.title}}</span>
                            <span class="new-total">新品{{cate.total}}款</span>
                        </div>
                        <div v-show="cate.show">
                            <div v-if="cate.comments" class="tips">
                                <span>研发语录：</span>
                                <p>{{cate.comments}}</p>
                            </div>
                            <div v-if="cate.boothsCount == 5" class="product-five">
                                <a href="javascript:;" v-for="(item, idx) in cate.goodslVOS" class="product-item"
                                    :class="'five' + idx"
                                    @click="jumpPage('/newrecommenddetail?id=' + item.goodsId, index)" v-if="idx < 5">
                                    <div class="goods-info">
                                        <h4>{{item.goodsTitle || '-'}}</h4>
                                        <p class="txt">{{item.goodsType}}</p>
                                        <div class="goods-tags">
                                            <span v-if="item.brand"
                                                :style="{color: item.fontColor, background: item.backgroundColor, borderColor: item.borderColor }">{{item.brand
                                                || '-'}}</span>
                                            <span v-if="item.recommendNew == 1" class="new">新品</span>
                                        </div>
                                        <p class="price">¥{{item.goodsPrice.toFixed(2)}}</p>
                                    </div>
                                    <img :src="item.goodsImg" alt="">
                                    <div class="small-title">{{item.goodsTitle}}</div>
                                </a>
                            </div>
                            <div v-else class="list-box">
                                <a href="javascript:;" v-for="(item, idx) in cate.goodslVOS" class="product-item"
                                    @click="jumpPage('/newrecommenddetail?id=' + item.goodsId, index)"
                                    v-if="idx < cate.boothsCount">
                                    <div class="goods-info">
                                        <h4>{{item.goodsType}}</h4>
                                        <p class="txt">{{item.goodsTitle}}</p>
                                        <div class="goods-tags">
                                            <span v-if="item.brand"
                                                :style="{color: item.fontColor, background: item.backgroundColor, borderColor: item.borderColor }">{{item.brand}}</span>
                                            <span v-if="item.recommendNew == 1" class="new">新品</span>
                                        </div>
                                        <p class="price">¥{{item.goodsPrice.toFixed(2)}}</p>
                                    </div>
                                    <img :src="item.goodsImg" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else class="no-goods">
                        暂无商品
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    export default {
        name: 'ProductList',
        components: {
            CommonHeader,
            BlackFooter,
        },
        data() {
            return {
                isScroll: false,
                banner: '',
                cateList: [],
                total: 0,
            }
        },
        mounted() {
            this.getList();
        },
        beforeRouteLeave(to, from, next) {
            let position = window.scrollY;
            localStorage.newTop = position;
            next();
        },
        beforeRouteEnter(to, from, next) {
            let name = from.name;
            if (name == 'NewDetail' || name == 'NewList') {
                next(vm => {
                    vm.isScroll = true;
                });
            }
            else {
                next();
            }
        },
        methods: {
            getList() {
                this.$api.getCateGoods({ belong: 0 }).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.cateList = this.initData(data);
                        if (this.isScroll) {
                            this.isScroll = false;
                            this.$nextTick(() => {
                                let position = localStorage.newTop || 0;
                                let idx = localStorage.newIdx;
                                window.scroll(0, position);
                                localStorage.newTop = 0;
                                this.cateList[idx].show = true;

                            })
                        }
                    }
                });

                this.$api.getActivityData('PCBANNER').then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.banner = data.filter(item => item.model == 'BANNER_3')[0].img;
                    }
                });
            },
            initData(data) {
                data.sort((a, b) => {
                    return a.sequence - b.sequence;
                });
                data.forEach((ele, idx) => {
                    this.total += ele.goodslVOS.length;
                    ele.total = ele.goodslVOS.length;
                    ele.show = false;
                });
                return data;
            },
            showGoods(idx) {
                this.cateList[idx].show = !this.cateList[idx].show;
            },
            jumpPage(path, idx) {
                this.$router.push({
                    path: path
                });
                localStorage.newIdx = idx;
            }
        }
    }
</script>
<style scoped>
    .right-main {
        padding-top: 432px;
        min-height: calc(100vh - 100px);
        padding-bottom: 70px;
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 18px;
        color: #444;
        margin-bottom: 40px;
        border-bottom: 1px solid #f1f1f1;
        padding-bottom: 25px;
    }

    .breadcrumb strong {
        margin-right: 20px;
        font-size: 20px;
    }

    .h2-title {
        line-height: 40px;
        font-size: 40px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        text-align: center;
        color: #444;
        margin-bottom: 24px;
    }

    .level-title {
        height: 72px;
        background: #f3f3f3 url(~@/assets/icon_new.png) no-repeat 40px center;
        border: 1px solid #d8d8d8;
        border-radius: 8px;
        margin-bottom: 14px;
        line-height: 72px;
        padding: 0 92px 0 105px;
        position: relative;
        font-size: 20px;
        cursor: pointer;
    }

    .level-title.show {
        color: #F36700;
        background: #f3f3f3 url(~@/assets/icon_new_set.png) no-repeat 40px center;
    }
    .level-title.show .cate-title{
        color: #F36700;
    }

    .level-title::after {
        content: "";
        display: block;
        position: absolute;
        right: 30px;
        top: 20px;
        width: 32px;
        height: 32px;
        background: url(~@/assets/icon_new_retract.png);
    }

    .level-title.show::after {
        background: url(~@/assets/icon_new_expand.png);
    }

    .cate-title {
        font-weight: bold;
        color: #444;
    }

    .new-total {
        margin-left: 60px;
        font-size: 18px;
    }

    .more {
        float: right;
        font-size: 16px;
        color: #F36700;
    }

    .tips {
        background: #ecf8ff url(~@/assets/icon_tips.png) no-repeat 30px 16px;
        border-radius: 8px;
        margin-bottom: 16px;
        font-size: 16px;
        color: #444;
        line-height: 24px;
        padding: 10px 0 10px 65px;
        min-height: 70px;
    }

    .tips span {
        float: left;
        font-weight: bold;
        letter-spacing: 2px;
    }

    .tips p {
        margin-left: 90px;
        padding-right: 20px;
    }

    .list-box {
        margin-bottom: 22px;
        text-align: center;
    }

    .list-box::after {
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .product-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 340px;
        height: 477px;
        display: inline-block;
        margin: 0 5px;
        border: 1px solid #f6f6f6;
        margin-bottom: 10px;
        overflow: hidden;
    }

    .product-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .product-item:hover img {
        transform: scale(1.1);
    }

    .product-item:hover h4 {
        color: #285CD6;
    }

    .product-item:first-child {
        margin-left: 0;
    }

    .product-item:last-child {
        margin-right: 0;
    }

    .product-item img {
        display: block;
        width: 234px;
        height: 234px;
        margin: 0 auto;
        transition: all .2s;
    }

    .product-item h4 {
        margin: 36px 0 8px;
        font-weight: 700;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        font-size: 20px;
    }

    .product-item .goods-tags {
        border: none;
        padding-bottom: 0;
        margin: 0 0 8px;
    }

    .goods-info {
        margin: 0 36px;
    }

    .product-five {
        height: 477px;
        margin-bottom: 24px;
        position: relative;
    }

    .product-five .product-item {
        background: #ffffff;
        border: 1px solid #f6f6f6;
        overflow: hidden;
        position: absolute;
    }

    .five0 {
        left: 0;
        top: 0;
    }

    .five1 {
        width: 691px;
        height: 234px;
        top: 0;
        left: 345px;
    }

    .five2 {
        width: 691px;
        height: 234px;
        top: 243px;
        left: 345px;
    }

    .five1 .goods-info,
    .five2 .goods-info {
        margin: 68px 300px 0 65px;
    }

    .five1 img,
    .five2 img {
        position: absolute;
        top: 0;
        right: 56px;
    }

    .small-title {
        display: none;
        position: relative;
    }

    .five3 {
        height: 234px;
        top: 0;
        left: 1045px;
    }

    .five4 {
        height: 234px;
        top: 243px;
        left: 1045px;
    }

    .five3 .goods-info,
    .five4 .goods-info {
        display: none;
    }

    .five3 .small-title,
    .five4 .small-title {
        display: block;
        text-align: center;
        font-size: 16px;
    }

    .five3 img,
    .five4 img {
        width: 191px;
        height: 191px;
        margin: 13px auto 0;
    }

    .txt {
        line-height: 16px;
        font-size: 16px;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 8px;
    }

    .price {
        line-height: 18px;
        font-size: 18px;
        font-weight: 700;
        color: #ff3838;
        margin-bottom: 70px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }
</style>