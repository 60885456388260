<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="product">
            <div class="banner">
                <swiper v-if="swiperData.length" ref="mySwiper" :options="swiperOptions">
                    <swiper-slide v-for="item in swiperData">
                        <router-link :to="'/engineeringlist?id=' + item.data" target="_blank">
                            <img :src="item.slideshowUrl" alt="">
                        </router-link>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="main">
                <div class="right-main">
                    <div class="breadcrumb">
                        <a href="javascript:;" @click="$router.back()">工程案例</a> > <a href="javascript:;" @click="$router.back()">{{title}}</a>
                    </div>
                    <div class="cate-item" v-for="item in list">
                        <div class="cate-title">
                            <h4>{{item.title}}</h4>
                        </div>
                        <div class="user-item" v-for="user in item.children">
                            <div class="name"><span>合作客户：{{user.customerName}}</span></div>
                            <div class="img-wrap">
                                <img v-for="img in user.defaultPicUrls" :src="img" alt="">
                                <img v-for="img in user.picUrls" :src="img" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    export default {
        name: 'Engineering',
        components: {
            CommonHeader,
            BlackFooter,
            swiper, swiperSlide
        },
        data() {
            return {
                id: this.$route.query.id,
                cateTwo: Number(this.$route.query.cateTwo),
                title: '',
                swiperOptions: {
                    loop: true,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                swiperData: [],
                list: []
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                if (this.id) {
                    this.getList(this.id, 1);
                }
                else {
                    this.getList(this.cateTwo);
                }

                this.$api.getSlideshow().then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.swiperData = data;
                    }
                });
            },
            getList(id, type) {
                let opt = {};
                if (type == 1) {
                    let ids = id.split(',');
                    if (ids.length == 1) {
                        opt = { cateLevelOneId: ids[0] };
                    }
                    else {
                        opt = { cateLevelTwoId: ids[1] };
                    }
                }
                else {
                    opt = { cateLevelTwoId: id };
                }
                this.$api.getEngineering(opt).then(res => {
                    let { data, success } = res.data;
                    if (success && data) {
                        Object.keys(data).forEach(key => {
                            if (!this.title) {
                                this.title = data[key][0].cateLevelOne;
                            }
                            this.list.push({
                                children: data[key],
                                title: data[key][0].cateLevelTwo
                            });
                        });
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .banner {
        height: 400px;
        overflow: hidden;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 400px;
    }

    .right-main {
        min-height: calc(100vh - 100px);
        padding-bottom: 200px;
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #444;
        padding: 32px 0 26px;
    }

    .cate-item {
        padding-bottom: 16px;
    }

    .cate-title {
        line-height: 20px;
        font-size: 20px;
        font-family: Source Han Sans CN, Source Han Sans CN-Bold;
        font-weight: 700;
        color: #444;
        padding-left: 8px;
        border-left: 8px solid #34b8ff;
        margin: 24px 0;
    }

    .name {
        margin-bottom: 8px;
    }

    .name span {
        padding: 13px 16px;
        height: 47px;
        background: #ecf8ff;
        border-radius: 8px;
        display: inline-block;
        line-height: 1;
    }

    .img-wrap {
        overflow: hidden;
        margin-bottom: 24px;
    }

    .img-wrap img {
        width: 455px;
        height: 455px;
        float: left;
        margin-right: 12px;
        margin-bottom: 12px;
    }

    .img-wrap img:nth-child(3n) {
        margin-right: 0;
    }
</style>