<template>
    <div class="footer">
        <div class="footer-main">
          <p>浙江星星冷链集成股份有限公司 版权所有</p>
          <p>地址：浙江省台州市椒江区洪家街道1688号</p>
          <p>400-7654-315 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">浙ICP备16039478号-7</a></p>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
    },
    data(){
      return {
      }
    },
    methods: {
    }
  }
  </script>
  <style scoped>
      .footer-main{
          text-align: center;
          padding: 47px 0 25px;
          background: #fff;
          font-size: 12px;
          color: #c7c7c7;
          line-height: 1;
      }
      .footer-main a{
          color: #c7c7c7;
          padding-left: 10px;
      }
      .footer-main p{
        margin-bottom: 8px;
      }
  </style>
  