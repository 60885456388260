<template>
  <div class="left-menu">
    <ul>
      <li v-for="(item, i) in list">
        <a v-if="type == 2" :class="{active: item.goodsCateId == $route.query.id || (!$route.query.id && i == 0)}"
          href="javascript:;" @click="chooseMenu(item)">{{item.title}}</a>
        <a v-else :class="{active: item.goodsCateId == $route.params.pid || (!$route.params.pid && i == 0)}"
          href="javascript:;" @click="chooseMenu(item)">{{item.title}}</a>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array
      },
      type: ''
    },
    data() {
      return {
      }
    },
    mounted() {
    },
    methods: {
      chooseMenu(item) {
        let path = '/product/list/' + item.goodsCateId;
        if (this.type == 2) {
          path = '/video?id=' + item.goodsCateId;
        }
        this.$router.push({
          path
        }, () => { });
      }
    }
  }
</script>
<style scoped>
  .left-menu {
    width: 150px;
    position: fixed;
    z-index: 999;
    left: 50%;
    margin-left: -695px;
    bottom: 30px;
    /* scrollbar-width: none;
    -ms-overflow-style: none;
    overflow-x: hidden;
    overflow-y: auto; */
  }

  .left-menu::-webkit-scrollbar {
    display: none;
  }

  .left-menu a {
    width: 150px;
    height: 43px;
    display: block;
    opacity: 0.82;
    background: rgba(42, 180, 255, 0.82);
    border-radius: 24px 0px 24px 0px;
    text-align: center;
    line-height: 43px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 16px;
    overflow: hidden;
  }

  .left-menu a.active,
  .left-menu a:hover {
    background: #FFDE23;
    font-size: 20px;
    font-weight: 700;
    color: #444;
    letter-spacing: 2px;
    opacity: 1;
  }
</style>