<template>
    <div class="wrap">
        <common-header></common-header>
        <div class="product">
            <div class="banner">
                <swiper v-if="swiperData.length" ref="mySwiper" :options="swiperOptions">
                    <swiper-slide v-for="item in swiperData">
                        <router-link :to="'/engineeringlist?id=' + item.data" target="_blank">
                            <img :src="item.slideshowUrl" alt="">
                        </router-link>
                    </swiper-slide>
                </swiper>
            </div>
            <div class="main">
                <div class="right-main">
                    <div class="breadcrumb">
                        工程案例 > {{title}}
                    </div>
                    <div v-if="goodsList.length" class="product-list">
                        <div class="list-box">
                            <router-link v-for="item in goodsList" class="product-item"
                                :to="'/engineeringdetail?id=' + item.goodsId">
                                <img :src="item.goodsImg" alt="">
                                <div class="goods-info">
                                    <h4>{{item.goodsType}}</h4>
                                    <p class="txt">{{item.goodsTitle}}</p>
                                    <div class="goods-tags">
                                        <span v-if="item.brand" :style="{color: item.fontColor, background: item.backgroundColor, borderColor: item.borderColor }">{{item.brand}}</span>
                                        <span v-if="item.recommendNew == 1" class="new">新品</span>
                                    </div>
                                    <p class="price">¥{{item.goodsPrice.toFixed(2)}}</p>
                                </div>
                            </router-link>
                        </div>
                        <div class="page-wrap">
                            <pagination v-show="total>0" :total="total" background
                                layout="prev, pager, next, total, jumper" :page.sync="listQuery.current"
                                :limit.sync="listQuery.size" @pagination="getList" />
                        </div>
                    </div>
                    <div v-else class="no-goods">
                        暂无商品
                    </div>
                </div>
            </div>
        </div>
        <black-footer></black-footer>
    </div>
</template>
<script>
    import CommonHeader from '@/components/CommonHeader';
    import BlackFooter from '@/components/BlackFooter';
    import Pagination from '@/components/Pagination'
    import 'swiper/dist/css/swiper.css'
    import { swiper, swiperSlide } from 'vue-awesome-swiper'
    export default {
        name: 'NewList',
        components: {
            CommonHeader,
            BlackFooter,
            Pagination,
            swiper, swiperSlide
        },
        data() {
            return {
                id: this.$route.query.id,
                title: '',
                banner: '',
                goodsList: [],
                listQuery: {
                    current: 1,
                    size: 20,
                },
                swiperOptions: {
                    loop: true,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev"
                    }
                },
                swiperData: [],
            }
        },
        mounted() {
            this.pageInit();
        },
        methods: {
            pageInit() {
                this.getList();

                this.$api.getCateDetail(this.id).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.title = data.title;
                    }
                });
                this.$api.getSlideshow().then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.swiperData = data;
                    }
                });
            },
            getList(){
                let opt = { ...this.listQuery, goodsCateId: this.id };
                this.$api.getGoodsList(opt).then(res => {
                    let { data, success } = res.data;
                    if (success) {
                        this.goodsList = data.rows;
                        this.total = data.total;
                    }
                });
            }
        }
    }
</script>
<style scoped>
    .banner {
        height: 400px;
        overflow: hidden;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        background-position: center;
        background-repeat: no-repeat;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
    }
    .right-main {
        padding-top: 32px;
        min-height: calc(100vh - 100px);
    }

    .breadcrumb {
        line-height: 20px;
        font-size: 20px;
        font-weight: 700;
        color: #444;
        padding-bottom: 30px;
    }

    .product-list {
        padding: 0 0 32px;
    }

    .list-box {
        margin-bottom: 22px;
    }

    .list-box::after {
        content: "";
        display: block;
        overflow: hidden;
        height: 0;
        clear: both;
    }

    .product-item {
        line-height: 18px;
        font-size: 18px;
        font-weight: 400;
        color: #444;
        width: 335px;
        float: left;
        margin-right: 16px;
        border: 1px solid #f6f6f6;
        margin-bottom: 16px;
        overflow: hidden;
    }

    .product-item:hover {
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.16);
    }

    .product-item:hover img {
        transform: scale(1.1);
    }

    .product-item:hover .txt {
        color: #285CD6;
    }

    .product-item:nth-child(4n) {
        margin-right: 0;
    }

    .product-item img {
        display: block;
        width: 268px;
        height: 268px;
        margin: 0 auto;
        transition: all .2s;
    }

    .product-item h4 {
        margin: 20px 0 10px;
        font-weight: 700;
        color: #444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        font-size: 20px;
    }

    .product-item .goods-tags {
        margin: 0 0 8px;
        padding-bottom: 10px;
        height: 32px;
    }

    .goods-info {
        margin: 0 20px;
    }

    .txt {
        line-height: 19px;
        font-size: 19px;
        color: #444;
        margin: 0 0 8px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .price {
        line-height: 22px;
        font-size: 21px;
        font-weight: 700;
        color: #285cd6;
        margin: 8px 0 16px;
    }

    .no-goods {
        line-height: 20px;
        font-size: 20px;
        text-align: center;
        color: #666;
        padding-top: 43px;
    }
</style>